import { Button, HFlow, Icon, Theme, Tooltip, useTheme } from 'bold-ui'
import { useFlags } from 'config/useFlagsContext'
import { css } from 'emotion'
import { ArquivoTemporarioStatusEnum } from 'graphql/types.generated'
import React from 'react'
import { isUndefinedOrNull } from 'util/checks'

const NAO_EXISTEM_DADOS_TEXT = 'Não existem dados para geração do relatório'
const EM_PROCESSAMENTO_TEXT = 'Relatório em processamento'
const IVCF_NO_PDF_E_CSV_TEXT =
  'O PDF e CSV exibe as informações do IVCF-20 do cidadão, inclusive pontuação e dimensões alteradas.'

interface RelatoriosButtonGroupProps {
  hasIvcfFilter?: boolean
  shouldRefetch: boolean
  statusRelatorio: ArquivoTemporarioStatusEnum
  numberOfElements: number
  otherButtons?: React.ReactNode
  onClickExportarCSV?: () => void
  onClickExportarPDF?: () => void
}

export function RelatoriosButtonGroup(props: RelatoriosButtonGroupProps) {
  const {
    hasIvcfFilter,
    shouldRefetch,
    statusRelatorio,
    numberOfElements,
    otherButtons,
    onClickExportarCSV: exportarCSV,
    onClickExportarPDF: exportarPDF,
  } = props

  const theme = useTheme()
  const classes = styles(theme)
  const { IVCF_ACOMPANHAMENTO_CONDICOES_ENABLED } = useFlags()

  if (!exportarPDF && !exportarCSV && !otherButtons) {
    return null
  }

  const hasResults = numberOfElements > 0
  const hasOtherButtons = !isUndefinedOrNull(otherButtons)

  const isRelatorioProcessando = shouldRefetch || statusRelatorio === ArquivoTemporarioStatusEnum.EM_PROCESSAMENTO

  const mensagemTooltipCsvAndPdf = isRelatorioProcessando
    ? EM_PROCESSAMENTO_TEXT
    : !hasResults
    ? NAO_EXISTEM_DADOS_TEXT
    : null

  const shouldDisableCsvAndPdf = !hasResults || isRelatorioProcessando

  return IVCF_ACOMPANHAMENTO_CONDICOES_ENABLED ? (
    <HFlow justifyContent={hasIvcfFilter || hasOtherButtons ? 'space-between' : 'flex-end'} hSpacing={0}>
      <HFlow justifyContent='flex-start'>{otherButtons}</HFlow>

      <HFlow justifyContent='flex-end' alignItems='center'>
        {hasIvcfFilter && (
          <Tooltip text={IVCF_NO_PDF_E_CSV_TEXT}>
            <Icon icon='infoCircleFilled' size={1} style={classes.infoIcon} />
          </Tooltip>
        )}
        {exportarCSV && (
          <Tooltip text={mensagemTooltipCsvAndPdf}>
            <Button onClick={exportarCSV} disabled={shouldDisableCsvAndPdf}>
              Exportar CSV
            </Button>
          </Tooltip>
        )}
        {exportarPDF && (
          <Tooltip text={mensagemTooltipCsvAndPdf}>
            <Button kind='primary' onClick={exportarPDF} disabled={shouldDisableCsvAndPdf}>
              <HFlow hSpacing={0.5}>
                <Icon icon='fileWithItemsOutline' />
                Imprimir
              </HFlow>
            </Button>
          </Tooltip>
        )}
      </HFlow>
    </HFlow>
  ) : (
    <HFlow justifyContent='flex-end' hSpacing={0}>
      <HFlow justifyContent='flex-end' alignItems='center'>
        {exportarCSV && (
          <Tooltip text={mensagemTooltipCsvAndPdf}>
            <Button onClick={exportarCSV} disabled={shouldDisableCsvAndPdf}>
              Exportar CSV
            </Button>
          </Tooltip>
        )}
        {otherButtons}
      </HFlow>
    </HFlow>
  )
}

const styles = (theme: Theme) => ({
  infoIcon: css`
    &:hover {
      fill: ${theme.pallete.primary.main};
      cursor: pointer;
    }
  `,
})

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, HFlow, Link, Table, TableBody, TableCell, TableHead, TableHeader, TableRow, Text, VFlow } from 'bold-ui'
import { useFlags } from 'config/useFlagsContext'
import { ReactComponent as Consulta } from 'images/historico/icone-consulta.svg'
import { ReactComponent as AcompanhamentoCondicoesDeSaudeIcon } from 'images/side-menu/acompanhamentos.svg'
import { ReactComponent as BolsaFamiliaIcon } from 'images/side-menu/bolsa-familia.svg'
import { ReactComponent as BuscaAtiva } from 'images/side-menu/busca-ativa.svg'
import { ReactComponent as ProfissionaisIcon } from 'images/side-menu/profissionais.svg'
import { ReactComponent as VideochamadaIcon } from 'images/side-menu/videochamadas.svg'

import { Carousel, CarouselItem } from './carousel/Corousel'
import { NewsGif } from './NewsGif'
import { NewsHeadingSection } from './NewsHeadingSection'
import { NewsImg } from './NewsImg'

export function NewsInternalPanelCincoTres() {
  const {
    CADASTRO_IMOVEL_ENABLED,
    SAUDE_INDIGENA_ENABLED,
    ACOMPANHAMENTO_CONDICAO_SAUDE_CSV,
    PILOTO_RECEBIMENTO_ONLINE_EXTERNO_ENABLED,
    PILOTO_VINCULACAO_CUIDADO_ENABLED,
    PILOTO_SOLICITAR_PROCED_OCI_ENABLED,
  } = useFlags()

  if (!process.env.REACT_APP_VERSION) {
    return (
      <Alert type='danger' inline>
        Nenhuma informação sobre versões anteriores está disponível.
      </Alert>
    )
  }

  return (
    <VFlow vSpacing={1}>
      <Text fontSize={0.875} fontWeight='bold'>
        Novidades:
      </Text>
      <HFlow hSpacing={5}>
        <VFlow vSpacing={0.2}>
          {PILOTO_SOLICITAR_PROCED_OCI_ENABLED && (
            <Text fontSize={0.875} component='li'>
              Solicitação de exames/procedimentos
            </Text>
          )}
          {ACOMPANHAMENTO_CONDICAO_SAUDE_CSV && (
            <Text fontSize={0.875} component='li'>
              Acompanhamento de condições de saúde
            </Text>
          )}
          <Text fontSize={0.875} component='li'>
            Agendamento entre profissionais
          </Text>
          <Text fontSize={0.875} component='li'>
            Atestado Digital
          </Text>
          <Text fontSize={0.875} component='li'>
            Busca ativa de vacinação
          </Text>
          {CADASTRO_IMOVEL_ENABLED && (
            <Text fontSize={0.875} component='li'>
              Cadastro de Imóveis e Famílias
            </Text>
          )}
          <Text fontSize={0.875} component='li'>
            Cuidado Compartilhado
          </Text>
          <Text fontSize={0.875} component='li'>
            Fixar profissionais na agenda
          </Text>
          <Text fontSize={0.875} component='li'>
            Prescrição de medicamentos
          </Text>
          <Text fontSize={0.875} component='li'>
            Unificação de base
          </Text>
          <Text fontSize={0.875} component='li'>
            Videochamada do profissional convidado adaptada para telefone celular
          </Text>
          <Text fontSize={0.875} component='li'>
            Videochamada do profissional convidado adaptada para tablet
          </Text>
          <Text fontSize={0.875} component='li'>
            Nome social do profissional
          </Text>
          <Text fontSize={0.875} component='li'>
            Índice de Vulnerabilidade Clínico-Funcional
          </Text>
          <Text fontSize={0.875} component='li'>
            Histórico de acessos ao sistema
          </Text>
          <Text fontSize={0.875} component='li'>
            Relatório de erros retornados pela RNDS
          </Text>
        </VFlow>
      </HFlow>

      {PILOTO_SOLICITAR_PROCED_OCI_ENABLED && (
        <NewsHeadingSection title='Solicitação de exames/procedimentos' id='solicitacao-exames-procedimentos-5-3'>
          <VFlow vSpacing={0.5}>
            <Text fontSize={0.875} component='li'>
              A aba "Solicitação de exames" no Plano do atendimento foi atualizada e renomeada para "Solicitação de
              exames/procedimentos". Além de permitir a solicitação de exames, agora esta aba também possibilita a
              solicitação de Ofertas de Cuidados Integrados.
            </Text>
            <NewsImg
              src='static/novidades/5-3_solicitacao_exames_procedimentos.png'
              alt='Imagem da aba Solicitação de exames/procedimentos no Plano do atendimento'
            />
          </VFlow>
        </NewsHeadingSection>
      )}
      {ACOMPANHAMENTO_CONDICAO_SAUDE_CSV && (
        <NewsHeadingSection
          title='Acompanhamento de condições de saúde'
          icon={<AcompanhamentoCondicoesDeSaudeIcon css={styles.iconBold} />}
          id='acompanhamento-de-condicoes-de-saude-5-3'
        >
          <VFlow vSpacing={0.5}>
            <Text fontSize={0.875} component='li'>
              Inclusão da exportação do relatório de busca em formato{' '}
              <Text fontSize={0.875} fontWeight='bold'>
                CSV
              </Text>
              , permitindo a manipulação dos dados.
            </Text>
            <Text fontSize={0.875} component='li'>
              Inclusão da visualização, de até 7 dias, dos relatórios que foram processados e que estão em
              processamento.
            </Text>
            <NewsGif
              src='/static/novidades/5-3_acompanhamento_condicoes_de_saude.gif'
              pausedSrc='/static/novidades/5-3_acompanhamento_condicoes_de_saude_paused.png'
              alt='Gif mostrando a exportação do relatório CSV'
            />
          </VFlow>
        </NewsHeadingSection>
      )}
      <NewsHeadingSection title='Agendamento entre profissionais' id='agendamento-entre-profissionais-5-3'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875}>
            Na Agenda, será possível registrar agendamentos com a participação de dois profissionais. Sendo possível
            inserir um cidadão no agendamento e indicar que a consulta será realizada via videochamada. O profissional
            que criar o agendamento será considerado o anfitrião e o outro profissional será o convidado.
          </Text>
          <Carousel>
            <CarouselItem
              image='/static/novidades/5-3_agendamento_entre_profissionais_cadastro.png'
              alt='Imagem do cadastro de um agendamento entre profissionais'
            />
            <CarouselItem
              image='/static/novidades/5-3_agendamento_entre_profissionais_detalhe.png'
              alt='Imagem do detalhe de um agendamento entre profissionais'
            />
          </Carousel>
          <Text fontSize={0.875}>
            Ao inserir um cidadão na lista de atendimentos a partir de um agendamento entre profissionais, serão
            exibidos os nomes dos dois profissionais que irão participar do atendimento:
          </Text>
          <NewsImg
            src='static/novidades/5-3_agendamento_entre_profissionais_la.png'
            alt='Imagem do agendamento entre profissionais inserido na lista de atendimentos'
          />
          <Text fontSize={0.875}>
            Caso o agendamento entre profissionais seja feito com a presença do cidadão e possua uma videochamada, um
            novo fluxo no sistema será liberado. O cidadão será inserido normalmente na lista de atendimento, porém
            quando o anfitrião iniciar o atendimento, a folha de rosto passará a exibir informações sobre a
            videochamada. Além disso, os campos Atendimento compartilhado e Forma de participação serão preenchidos
            automaticamente.
          </Text>
          <Carousel>
            <CarouselItem
              image='/static/novidades/5-3_agendamento_entre_profissionais_folha_rosto.png'
              alt='Imagem do card com detalhes da videochamada na folha de rosto'
            />
            <CarouselItem
              image='/static/novidades/5-3_agendamento_entre_profissionais_finalizacao.png'
              alt='Imagem dos campos de tipo de participação na finalização do atendimento'
            />
          </Carousel>
          <Text fontSize={0.875}>
            Caso outro profissional atenda o cidadão que está aguardando um agendamento entre profissionais com
            videochamada, o seguinte modal será exibido. Isso acontece para frisar que as opções de videochamada e o
            preenchimento automático do campo "Atendimento compartilhado" só serão liberadas quando o anfitrião realizar
            o atendimento.
          </Text>
          <NewsImg
            src='static/novidades/5-3_agendamento_entre_profissionais_modal.png'
            alt='Imagem do modal com informações do agendamento entre profissionais'
          />
          <Text fontSize={0.875}>
            As informações sobre a forma de participação do cidadão e do profissional convidado serão exibidas da
            seguinte forma no histórico:
          </Text>
          <Carousel>
            <CarouselItem
              image='/static/novidades/5-3_agendamento_entre_profissionais_historico_cidadao.png'
              alt='Imagem do histórico indicando a forma de participação do cidadão'
            />
            <CarouselItem
              image='/static/novidades/5-3_agendamento_entre_profissionais_historico_profissional.png'
              alt='Imagem do histórico indicando a forma de participação do profissional do atendimento compartilhado'
            />
          </Carousel>

          <Text fontSize={0.875}>
            As informações serão enviadas normalmente por meio da ficha de atendimento individual e da ficha de
            atendimento odontológico.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Atestado Digital' icon={<Consulta css={styles.iconSVG} />} id='atestado-digital-5-3'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Ao gerar um atestado para o cidadão, será possível{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              gerar um atestado digital
            </Text>{' '}
            através da opção “Gerar e enviar atestado digital”, caso a instalação esteja habilitada para assinaturas
            digitais.
          </Text>
          <Text fontSize={0.875} component='li'>
            É possível editar o e-mail onde será enviado o atestado antes de iniciar o processo de assinatura, clicando
            no ícone de edição ao lado do endereço de e-mail.
          </Text>
          <NewsGif
            src='/static/novidades/5-3_atestado_digital_gerar.gif'
            pausedSrc='/static/novidades/5-3_atestado_digital_gerar_paused.webp'
            alt='Demonstração de funcionalidade da geração do atestado digital.'
          />
          <Text fontSize={0.875} component='li'>
            Para gerar um atestado digital, é necessário realizar uma{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              assinatura digital
            </Text>{' '}
            do documento, utilizando um certificado digital ICP-Brasil. O PEC irá abrir uma nova janela para o processo
            de assinatura.
          </Text>
          <Text fontSize={0.875} component='li'>
            Após a assinatura, o documento assinado será enviado ao cidadão no e-mail cadastrado.
          </Text>
          <NewsGif
            src='/static/novidades/5-3_atestado_digital_assinatura.gif'
            pausedSrc='/static/novidades/5-3_atestado_digital_assinatura_paused.webp'
            alt='Demonstração de funcionalidade da assinatura do atestado digital.'
          />
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection
        title='Busca ativa de vacinação'
        icon={<BuscaAtiva css={styles.iconBold} />}
        id='busca-ativa-vacinacao-5-3'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Implementação do Grupo-alvo de{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Adultos
            </Text>{' '}
            para Vacinas do calendário vacinal;
          </Text>
          <Text fontSize={0.875} component='li'>
            Implementação do Grupo-alvo de{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Puérperas com Gestantes
            </Text>{' '}
            para Vacinas do calendário vacinal;
          </Text>
          <Text fontSize={0.875} component='li'>
            Unificação dos Grupos-alvo de{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Gestantes e Puérperas
            </Text>{' '}
            para Vacinas do calendário vacinal;
          </Text>
          <Text fontSize={0.875} component='li'>
            Inclusão do filtro por{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Bairro, Tipo de Logradouro
            </Text>{' '}
            e{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Logradouro
            </Text>{' '}
            de residência do cidadão;
          </Text>
          <Text fontSize={0.875} component='li'>
            Inclusão da regra de{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Identidade de gênero
            </Text>{' '}
            nos relatórios.
          </Text>
          <NewsGif
            src='/static/novidades/5-3_busca_ativa_vacinacao_filtros_endereco.gif'
            pausedSrc='/static/novidades/5-3_busca_ativa_vacinacao_filtros_endereco_paused.png'
            alt='Gif mostrando os filtros de endereço na busca ativa de vacinação'
          />
        </VFlow>
      </NewsHeadingSection>

      {CADASTRO_IMOVEL_ENABLED && (
        <NewsHeadingSection title='Cadastro de Imóveis e Famílias' id='cadastro-imoveis-familias-5-3'>
          <VFlow vSpacing={0.5}>
            <Text fontSize={0.875} component='li'>
              A versão 5.3 agora permite cadastrar imóveis e famílias de forma mais completa e clara, facilitando a
              gestão dos cidadãos moradores de cada domicílio adscrito.
            </Text>
            <Text fontSize={0.875} component='li'>
              A funcionalidade de Acompanhamento do Território, no menu lateral, foi evoluída para permitir cadastrar e
              atualizar os imóveis e famílias, condições de moradia e responsabilidades de acompanhamento, tudo isso em
              uma única interface moderna e intuitiva, sem a necessidade de gerar várias Fichas CDS manualmente. O
              sistema as gerará automaticamente, apenas para que a sua produção seja consolidada em relatórios e enviada
              ao Centralizar Nacional.
            </Text>
            <Text fontSize={0.875} component='li'>
              Também será possível visualizar e corrigir inconsistências de cadastro através das novas telas, sem a
              necessidade de se aguardar o processamento após o Envio de Lotes de Fichas.
            </Text>
            <NewsImg
              src='/static/novidades/5-3_cadastro_ imoveis_familias.png'
              alt='Imagem exibindo a lista de famílias no cadastro de imóveis'
            />
          </VFlow>
        </NewsHeadingSection>
      )}

      <NewsHeadingSection
        title='Cuidado Compartilhado'
        icon={<ProfissionaisIcon css={styles.iconBold} />}
        id='cuidado-compartilhado-5-3'
      >
        <VFlow vSpacing={0.5}>
          {PILOTO_VINCULACAO_CUIDADO_ENABLED && (
            <Text fontSize={0.875} component='li'>
              O módulo de cuidado compartilhado foi aprimorado para atendimentos individuais:
              <ul>
                <Text fontSize={0.875} component='li'>
                  <b>Guias de encaminhamento:</b> Agora, as guias são geradas exclusivamente pela aba do Cuidado
                  compartilhado, utilizando CBO e classificação de prioridade. A aba de Encaminhamentos permanece apenas
                  para consulta de encaminhamentos realizados anteriormente por ela, não permitindo mais a geração de
                  novos.
                </Text>
                <Text fontSize={0.875} component='li'>
                  <b>Atualização na impressão da guia de encaminhamento:</b> A impressão das guias de encaminhamento foi
                  atualizada para incluir informações importantes, como endereço, CPF e CNS do cidadão. Além disso, a
                  quantidade de caracteres permitida no campo de texto foi ampliada para até 2000 caracteres.
                </Text>
                <Text fontSize={0.875} component='li'>
                  <b>Compartilhamento de cuidados:</b> Agora é possível compartilhar cuidados entre estabelecimentos da
                  Atenção Primária e serviços de referência especializados, facilitando a integração do cuidado entre os
                  diferentes níveis de atenção.
                </Text>
              </ul>
              <NewsImg
                src='/static/novidades/5-3_compartilhamento_cuidado_plano.png'
                alt='Imagem exibindo a tela do compartilhamento de cuidado dentro do plano de um atendimento individual'
              />
            </Text>
          )}

          <Text fontSize={0.875} component='li'>
            Inclusão de novas opções de condutas na discussão de caso:
            <ul>
              <Text fontSize={0.875} component='li'>
                Conduta "Enviar para garantia de acesso" permite ao profissional executante registrar e enviar uma
                demanda para a Garantia de Acesso;
              </Text>
              <Text fontSize={0.875} component='li'>
                Conduta "Agendar consulta entre profissionais" possibilita o agendamento de consulta entre o
                profissional solicitante e executante sem a presença do cidadão;
              </Text>
              <Text fontSize={0.875} component='li'>
                Conduta "Agendar consulta entre profissionais via videochamada" possibilita o agendamento de consulta
                via videochamada entre o profissional solicitante e executante sem a presença do cidadão;
              </Text>
              <Text fontSize={0.875} component='li'>
                Conduta "Agendar consulta entre profissionais com o cidadão" possibilita o agendamento de consulta entre
                o profissional solicitante e executante com a presença do cidadão;
              </Text>
              <Text fontSize={0.875} component='li'>
                Conduta "Agendar consulta entre profissionais com o cidadão via videochamada" possibilita o agendamento
                de consulta via videochamada entre o profissional solicitante e executante com a presença do cidadão.
              </Text>
            </ul>
          </Text>
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection
        title='Prescrição de medicamentos'
        icon={<Consulta css={styles.iconBold} />}
        id='prescricao-medicamentos-5-3'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875}>
            <b>Inclusão de doses fracionadas por turno</b>
          </Text>
          <Text fontSize={0.875} component='li'>
            Para aproximar o PEC cada vez mais à realidade da rotina do profissional, agora é possível que as doses
            sejam fracionadas por turno: manhã, tarde e noite. Para isso, uma nova aba foi criada na modal de prescrição
            de medicamentos: <Text fontWeight='bold'>Fracionado por turno</Text>.
          </Text>
          <Text fontSize={0.875} component='li'>
            Para utilizá-la, selecione o tipo da dose <Text fontWeight='bold'>"Fracionada"</Text>, informe a quantidade
            total da dose, a sua unidade de medida, e as quantidades organizadas pelos turnos indicados.
          </Text>
          <Text fontSize={0.875} component='li'>
            É necessário informar a quantidade de pelo menos dois turnos. A soma das doses organizadas por turnos deve
            ser igual à quantidade total da dose, visto que a separação por turno é para tornar mais fácil o
            entendimento do cidadão em relação ao uso do medicamento.
          </Text>
          <Text fontSize={0.875} component='li'>
            A informação de dose fracionada por turno é descrita na receita impressa.
          </Text>
          <NewsGif
            src='/static/novidades/5-3_dose_fracionada.gif'
            pausedSrc='/static/novidades/5-3_dose_fracionada_paused.png'
            alt='Demonstração do preenchimento da prescrição de medicamentos com doses fracionadas por turno'
          />
          <Carousel>
            <CarouselItem
              image='/static/novidades/5-3_dose_fracionada_img_1.png'
              alt='Imagem do módulo da prescrição de medicamentos com dose fracionada por turno'
            />
            <CarouselItem
              image='/static/novidades/5-3_dose_fracionada_img_2.png'
              alt='Imagem da informação de dose fracionada por turno na receita impressa'
            />
          </Carousel>
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection title='Unificação de base' id='unificacao-base-5-3'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Este módulo permite que estabelecimentos de saúde, que possuem várias instalações em um mesmo município,
            unifiquem suas informações em uma única base de dados. Esse processo centraliza todas as instalações do
            município em uma única instalação.
          </Text>
          <ul>
            <Text fontSize={0.875} component='li'>
              O processo é iniciado com a solicitação de envio das informações da instalação desejada para a instalação
              que será a central do município.
            </Text>
            <Text fontSize={0.875} component='li'>
              Após essa solicitação ser feita, o administrador da instalação central deverá aprovar a solicitação para
              que o processo de unificação das bases seja iniciado.
            </Text>
          </ul>
          <Carousel>
            <CarouselItem
              image='/static/novidades/5-3_unificacao_base_pedido_local.png'
              alt='Imagem da tela de envio de dados'
            />
            <CarouselItem
              image='/static/novidades/5-3_unificacao_base_aceito_central.png'
              alt='Imagem da tela de recebimento de dados'
            />
            <CarouselItem
              image='/static/novidades/5-3_unificacao_base_envio_local.png'
              alt='Imagem do início da unificação de base'
            />
            <CarouselItem
              image='/static/novidades/5-3_unificacao_base_concluida_central.png'
              alt='Imagem da finalização da unificação de base'
            />
          </Carousel>
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection
        title='Videochamada do profissional convidado adaptada para telefone celular'
        icon={<VideochamadaIcon css={styles.iconBold} />}
        id='videochamada-mobile-5-3'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Os componentes da videochamada foram adaptados para funcionar em telefones celular. O objetivo do módulo é
            possibilitar que o profissional convidado acesse a videochamada pelo telefone celular. Por outro lado, o
            profissional anfitrião ainda deve acessar a videochamada através do computador.
          </Text>
          <Text fontSize={0.875} component='li'>
            As funcionalidades da videochamada para telefone celular são:
          </Text>
          <ul>
            <Text fontSize={0.875} component='li'>
              Ativar e desativar camera;
            </Text>
            <Text fontSize={0.875} component='li'>
              Ativar e desativar microfone;
            </Text>
            <Text fontSize={0.875} component='li'>
              Chat.
            </Text>
          </ul>
          <Carousel>
            <CarouselItem
              image='/static/novidades/5-3_videochamada_mobile_sala_espera.png'
              alt='Imagem da sala de espera da videochamada adaptada para mobile'
            />
            <CarouselItem
              image='/static/novidades/5-3_videochamada_mobile_termos.png'
              alt='Imagem dos termos da videochamada adaptados para mobile'
            />
            <CarouselItem
              image='/static/novidades/5-3_videochamada_mobile_chamada.png'
              alt='Imagem da videochamada adaptada para mobile'
            />
          </Carousel>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection
        title='Videochamada do profissional convidado adaptada para tablet'
        icon={<VideochamadaIcon css={styles.iconBold} />}
        id='videochamada-tablet-5-3'
      >
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Os componentes da videochamada foram adaptados para funcionar corretamente em tablets. O objetivo do módulo
            é adaptar as telas da videochamada para tablet. Todas as funcionalidades da videochamada foram mantidas.
          </Text>
          <Carousel>
            <CarouselItem
              image='/static/novidades/5-3_videochamada_sala_espera_tablet.png'
              alt='Sala de espera no tablet'
            />
            <CarouselItem
              image='/static/novidades/5-3_termo_responsabilidade_tablet.png'
              alt='Termo de responsabilidade no tablet'
            />
            <CarouselItem image='/static/novidades/5-3_videochamada_tablet.png' alt='Videochamada no tablet' />
          </Carousel>
        </VFlow>
      </NewsHeadingSection>

      {SAUDE_INDIGENA_ENABLED && (
        <NewsHeadingSection title='Saúde indígena' id='saude-indigena-5-3'>
          <VFlow vSpacing={0.5}>
            <Text fontSize={0.875} component='li'>
              Com objetivo de tornar o PEC cada vez mais acessível aos diversos cenários existentes da saúde pública, o
              sistema foi atualizado para abranger as especificidades do contexto da saúde indígena!
            </Text>
            <ul>
              <Text fontSize={0.875} component='li'>
                <Text fontSize={0.875} fontWeight='bold'>
                  Cadastro do cidadão:
                </Text>{' '}
                Inclusão de novos campos, como "Nome tradicional", "Função social", "Aldeia de nascimento", entre
                outros. Além disso, o endereço também foi adaptado ao contexto, com campos para indicar DSEI, Polo base
                e aldeia;
              </Text>
              <Text fontSize={0.875} component='li'>
                <Text fontSize={0.875} fontWeight='bold'>
                  Busca do cidadão:
                </Text>{' '}
                Inclusão do campo "Etnia", com opção "Não indígena" e campos para buscar através do endereço de
                residência: "DSEI", "Polo Base" e "Aldeia";
              </Text>
              <Text fontSize={0.875} component='li'>
                <Text fontSize={0.875} fontWeight='bold'>
                  Cadastro de imóveis:
                </Text>{' '}
                Inclusão de novas opções nos campos que caracterizam os imóveis, como "tipo de domicílio", "material
                predominante na construção", "abastecimento de água" entre outros. O endereço também foi adaptado;
              </Text>
              <Text fontSize={0.875} component='li'>
                <Text fontSize={0.875} fontWeight='bold'>
                  Ficha de atendimento individual:
                </Text>{' '}
                Inclusão de opções de exames e condições mais avaliadas nos atendimentos.
              </Text>
            </ul>
            <Carousel>
              <CarouselItem image='/static/novidades/5-3_cadastro_si.png' alt='Imagem do cadastro do cidadão aldeado' />
              <CarouselItem image='/static/novidades/5-3_busca_si.png' alt='Imagem da busca do cidadão aldeado' />
              <CarouselItem image='/static/novidades/5-3_imovel_si.png' alt='Imagem do cadastro de imóvel SI' />
              <CarouselItem
                image='/static/novidades/5-3_imovel_si_condicoes.png'
                alt='Imagem do cadastro de imóvel SI - condições de moradia'
              />
            </Carousel>
          </VFlow>
        </NewsHeadingSection>
      )}

      <NewsHeadingSection
        title='Importação do bolsa família'
        icon={<BolsaFamiliaIcon css={styles.iconBold} />}
        id='bolsa-familia-5-3'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Agora será possível importar um arquivo com os beneficiários do bolsa família de uma vigência específica
            para que eles sejam identificados em seus prontuários.
          </Text>
          <Text fontSize={0.875} component='li'>
            O arquivo poderá ser baixado no{' '}
            <Link href='https://egestorab.saude.gov.br/' target='_blank'>
              e-Gestor
            </Link>
            e incluído para importação na funcionalidade de Importação do bolsa família, que pode ser acessada pelo menu
            lateral.
          </Text>
          <Text fontSize={0.875} component='li'>
            É possível importar arquivos de qualquer vigência, inclusive listas complementares.
          </Text>

          <NewsGif
            src='/static/novidades/5-3_importacao_beneficiarios_bolsa_familia.gif'
            pausedSrc='/static/novidades/5-3_importacao_beneficiarios_bolsa_familia_paused.png'
            alt='Gif mostrando importação do arquivo de beneficiários do bolsa família'
          />

          <Text fontSize={0.875} component='li'>
            Ao visualizar a folha de rosto de um cidadão que consta como beneficiário no arquivo importado da vigência
            mais atual, será apresentada uma sinalização de que ele é beneficiário do Programa Bolsa Família.
          </Text>

          <NewsImg
            src='/static/novidades/5-3_alerta_cidadao_beneficiario_bolsa_familia.png'
            alt='Imagem exibindo mensagem indicativa que o cidadão atendido é beneficiário do bolsa família'
          />
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection title='Nome social do profissional' id='nome-social-profissional-5-3'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            A tela de registro e edição de profissionais conta com o campo Nome social:
          </Text>
          <NewsImg
            src='/static/novidades/5-3_nome_social_cadastro_edicao.png'
            alt='Imagem exibindo a tela de cadastro e edição de profissionais com o campo nome social'
          />
          <Text fontSize={0.875} component='li'>
            A tela com as informações do profissional passará a exibir o nome social:
          </Text>
          <NewsImg
            src='/static/novidades/5-3_nome_social_informacoes_profissional.png'
            alt='Imagem exibindo a tela de informações do profissional com o nome social'
          />
          <Text fontSize={0.875} component='li'>
            Nos campos de busca caso um usuário busque pelo nome civil ou social do profissional o sistema deve retornar
            somente o nome social do profissional:
          </Text>
          <NewsImg
            src='/static/novidades/5-3_nome_social_busca.png'
            alt='Imagem exibindo a tela de busca com o nome social do profissional'
          />
          <Text fontSize={0.875} component='li'>
            Durante o atendimento todas as impressões passarão a contar com o nome social do profissional:
          </Text>
          <NewsImg
            src='/static/novidades/5-3_nome_social_impressao.png'
            alt='Imagem exibindo a impressão com o nome social do profissional'
          />
          <Text fontSize={0.875} component='li'>
            Os registro visualizados no atendimento também passarão a contar com o nome social do profissional:
          </Text>
          <NewsImg
            src='/static/novidades/5-3_nome_social_registro_visualizado.png'
            alt='Imagem exibindo o registro visualizado no atendimento com o nome social do profissional'
          />
          <Text fontSize={0.875} component='li'>
            O histórico do cidadão passará a exibir o nome social do profissional:
          </Text>
          <Carousel>
            <CarouselItem
              image='/static/novidades/5-3_nome_social_historico_cidadao.png'
              alt='Histórico do cidadão com o nome social do profissional'
            />
            <CarouselItem
              image='/static/novidades/5-3_nome_social_historico_cidadao_informacoes_administrativas.png'
              alt='Histórico do cidadão com o nome social do profissional nas informações administrativas'
            />
          </Carousel>
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection
        title='IVCF-20 (Índice de Vulnerabilidade Clínico-Funcional)'
        icon={<Consulta css={styles.iconBold} />}
        id='indice-de-vulnerabilidade-clinico-funcional-5-3'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Agora no atendimento de idosos (pessoas com 60 anos ou mais), é apresentada a possibilidade do preenchimento
            do <Text fontWeight='bold'>IVCF-20 (Índice de Vulnerabilidade Clínico-funcional)</Text>. O resultado do
            índice ficará salvo no card de <Text fontWeight='bold'>Acompanhamento do Idoso</Text>, presente na folha de
            rosto do prontuário do cidadão e na lateral do atendimento. Além disso, o IVCF-20 pode ser recalculado
            sempre que necessário, com todas as informações anteriores mantidas no histórico clínico.
          </Text>
          <NewsGif
            src='/static/novidades/5-3_selecao_e_preenchimento_ivcf.gif'
            pausedSrc='/static/novidades/5-3_selecao_e_preenchimento_ivcf_paused.png'
            alt='Gif mostrando a seleção do botão IVCF-20 e um breve preenchimento do formulário'
          />
          <Text fontSize={0.875} component='li'>
            Todos os profissionais com acesso ao atendimento individual, atendimento odontológico e atendimento de
            procedimentos estão habilitados a preencher o IVCF-20. Preencher o instrumento é opcional, mas para calcular
            o resultado, todas as perguntas precisam ser respondidas obrigatoriamente.
          </Text>
          <NewsGif
            src='/static/novidades/5-3_resultado_ivcf.gif'
            pausedSrc='/static/novidades/5-3_resultado_ivcf_paused.png'
            alt='Gif mostrando o resultado do cálculo de um IVCF-20'
          />
        </VFlow>
      </NewsHeadingSection>

      {PILOTO_RECEBIMENTO_ONLINE_EXTERNO_ENABLED && (
        <NewsHeadingSection
          title='API para transmissão de registros no formato LEDI'
          id='api-transmissao-de-registros-5-3'
        >
          <VFlow vSpacing={0.5}>
            <Text fontSize={0.875} component='li'>
              Essa funcionalidade é voltada para municípios que utilizam sistemas terceiros. A partir de agora é
              possível realizar o registro thrift via API. Para isso, é necessário que a instalação possua HTTPS
              configurado e que o Administrador da instalação crie credencias para os sistemas terceiros.
            </Text>
            <Text fontSize={0.875} component='li'>
              Após entrar no sistema como Administrador da instalação através da lateral do sistema, é possível acessar
              o módulo de “Transmissão de dados”. No final da página existe uma nova seção “Credenciais para API”,
              criada para gerar as credenciais dos sistemas que irão enviar dados via API.
            </Text>
            <Text fontSize={0.875} component='li'>
              Os registros enviados via API podem ser visualizados pelo Administrador municipal no módulo de
              “Transmissão de dados”.
            </Text>
            <Text fontSize={0.875} component='li'>
              Lembrete: o usuário e a senha da credencial serão exibidos para o usuário somente uma única vez. Essas
              credenciais devem ser utilizadas para enviar os dados para a API.
            </Text>
            <Carousel>
              <CarouselItem
                image='/static/novidades/5-3_api_transmissao_de_lotes_modulo_transmissao_de_dados.png'
                alt='Imagem do módulo "Transmissão de Dados"'
              />
              <CarouselItem
                image='/static/novidades/5-3_api_transmissao_de_lotes_credencial_gerada.png'
                alt='Imagem da credencial para API gerada'
              />
              <CarouselItem
                image='/static/novidades/5-3_api_transmissao_de_lotes_credencial_ativa.png'
                alt='Imagem da credencial para API ativa'
              />
            </Carousel>
            <Text fontSize={0.875} component='li'>
              Após gerar as credencias, os sistemas terceiros podem iniciar os envios do registro thrift via API. Para
              isso, os seguintes endpoints foram criados:
            </Text>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeader>Endpoint</TableHeader>
                  <TableHeader>Tipo de requisição</TableHeader>
                  <TableHeader>Parâmetros</TableHeader>
                  <TableHeader>Observação</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>api/recebimento/login</TableCell>
                  <TableCell>POST</TableCell>
                  <TableCell>usuario</TableCell>
                  <TableCell>
                    Utilizado o usuário fornecido nas credenciais geradas pelo PEC para a autenticação da API
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>api/recebimento/login</TableCell>
                  <TableCell>POST</TableCell>
                  <TableCell>senha</TableCell>
                  <TableCell>
                    Utilizada a senha fornecida nas credenciais geradas pelo PEC para a autenticação da API
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>api/v1/recebimento/ficha</TableCell>
                  <TableCell>POST</TableCell>
                  <TableCell>ficha</TableCell>
                  <TableCell>
                    Enviar o path dos modelos de informação do LEDI na requisição. O thrift deve estar em binário. O
                    nome do arquivo deve ser “uuidDadoSerializado”, que está presente no modelo de informação.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeader>Endpoint</TableHeader>
                  <TableHeader>Resposta</TableHeader>
                  <TableHeader>Observação</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>api/recebimento/login</TableCell>
                  <TableCell>200</TableCell>
                  <TableCell>Acesso autorizado.</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>api/recebimento/login</TableCell>
                  <TableCell>400</TableCell>
                  <TableCell>Acesso negado, verificar credenciais utilizadas</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>api/v1/recebimento/ficha</TableCell>
                  <TableCell>201</TableCell>
                  <TableCell>Envio realizado com sucesso</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>api/v1/recebimento/ficha</TableCell>
                  <TableCell>400</TableCell>
                  <TableCell>
                    Algumas das fichas enviadas possuem erros. A ficha que apresenta o erro e o erro são enviados na
                    resposta da API.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Text fontSize={0.875} component='li'>
              A coluna "Origem do lote" deve possuir o valor "Sistemas terceiros via API".
            </Text>
            <Text fontSize={0.875} component='li'>
              Caso algum erro ocorra, é possível baixar o relatório de erros através dessa tela.
            </Text>
            <NewsImg
              src='/static/novidades/5-3_api_transmissao_de_lotes_gerar_relatorio_de_inconsistencias.png'
              alt='Imagem da tela onde é possível gerar relatório de inconsistências'
            />
          </VFlow>
        </NewsHeadingSection>
      )}
      {PILOTO_VINCULACAO_CUIDADO_ENABLED && (
        <NewsHeadingSection title='Serviços de referência' id='servicos-de-referencia-5-3'>
          <VFlow vSpacing={0.5}>
            <Text fontSize={0.875} component='li'>
              Criação do módulo de Serviços de referência no menu Gestão municipal, que estabelece e gerencia vínculos
              entre os serviços de referência especializados e atenção primária, permitindo o compartilhamento de
              cuidado entre unidades através do atendimento individual.
            </Text>
            <NewsImg
              src='/static/novidades/5-3_servicos_de_referencia.png'
              alt='Imagem exibindo a tela de serviços de referência no sistema'
            />
          </VFlow>
        </NewsHeadingSection>
      )}
      <NewsHeadingSection title='Fixar profissionais na agenda' id='fixar-profissionais-agenda-5-3'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875}>
            O propósito da funcionalidade de fixar profissionais na agenda é facilitar a busca por profissionais de
            saúde durante o ato do agendamento. A busca por profissional agora é dividida em duas seções: "Acesso
            rápido" e "Outros profissionais":
          </Text>
          <Text fontSize={0.875} component='li'>
            Caso não exista nenhum profissional fixado, a seção "Outros profissionais" deverá carregar todos os
            profissionais com os quais o usuário pode registrar agendamento;
          </Text>
          <Text fontSize={0.875} component='li'>
            Caso existam profissionais fixados, a seção "Acesso rápido" deve carregar todos os profissionais fixados e a
            seção "Outros profissionais" só apresentará resultado caso o usuário busque por um nome no campo
            Profissionais.
          </Text>
          <Text fontSize={0.875}>
            O primeiro passo para fixar um profissional é buscar o nome do profissional no módulo da agenda.
          </Text>
          <Carousel>
            <CarouselItem
              image='/static/novidades/5-3_fixar_profissional_agenda_buscar_vazio.png'
              alt='Campo de busca de profissional na Agenda sem busca preenchida'
            />
            <CarouselItem
              image='/static/novidades/5-3_fixar_profissional_agenda_buscar_preenchido.png'
              alt='Campo de busca de profissional na Agenda com busca preenchida'
            />
          </Carousel>
          <Text fontSize={0.875}>
            O segundo passo é selecionar o profissional desejado e depois clicar no ícone "Alfinete" que fica ao lado do
            campo de busca de profissionais:
          </Text>
          <NewsImg
            src='/static/novidades/5-3_fixar_profissional_agenda_botao_fixar.png'
            alt='Destaque do botão com ícone de alfinete para fixar profissionais na lista de acesso rápido'
          />
          <Text fontSize={0.875}>
            Após fixar um profissional, o nome do profissional fixado será exibido na seção de acessos rápidos. A lista
            de acesso rápido será acessada em qualquer componente relacionado a agenda.
          </Text>
          <Carousel>
            <CarouselItem
              image='/static/novidades/5-3_fixar_profissional_agenda_acesso_rapido_agenda.png'
              alt='Lista de profissionais da Agenda exibindo o profissional fixado na sessão "Acesso rápido" no módulo de Agenda'
            />
            <CarouselItem
              image='/static/novidades/5-3_fixar_profissional_agenda_acesso_rapido_soap.png'
              alt='Lista de profissionais da Agenda exibindo o profissional fixado na sessão "Acesso rápido" na sessão "Agendar consulta" do SOAP'
            />
          </Carousel>
          <Text fontSize={0.875}>
            Para retirar um profissional da lista de acesso rápido, é necessário entrar no módulo da Agenda, selecionar
            o profissional e clicar novamente no ícone de "Alfinete".
          </Text>
          <NewsImg
            src='/static/novidades/5-3_fixar_profissional_agenda_botao_remover_fixado.png'
            alt='Destaque do botão com ícone de alfinete para retirar profissional da lista de acesso rápido'
          />
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Histórico de acessos ao sistema' id='historico-acessos-5-3'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Através do menu superior direito é possível acessar o histórico de acessos ao sistema do usuário que está
            logado.
          </Text>
          <NewsImg
            src='/static/novidades/5-3_historico_acesso_menu.png'
            alt='Imagem exibindo o menu de histórico de acessos ao sistema com a opção de acessar o histórico de acessos'
          />
          <Text fontSize={0.875} component='li'>
            Ao clicar no botão "Ver histórico de acessos" o sistema abrirá uma tela com uma relação de todos os acessos
            do usuário que está logado.
          </Text>
          <NewsImg
            src='/static/novidades/5-3_historico_acessos.png'
            alt='Imagem exibindo tabela com acessos ao sistema do usuario logado'
          />
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection title='Relatório de erros retornados pela RNDS' id='relatorio-erros-rnds-5-3'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            O relatório de erros retornados pela RNDS é voltado para Administradores municipais. Sendo possível acessar
            o relatório entrando no módulo "Gestão Municipal" e depois na aba "RNDS". A tela conta com um novo botão
            "Gerar relatório de erros" ao clicar no botão um modal será aberto.
          </Text>
          <NewsImg
            src='/static/novidades/5-3_gestao_municipal_rnds.png'
            alt='Imagem exibindo a tela de gestão municipal com a aba RNDS'
          />
          <Text fontSize={0.875} component='li'>
            No modal será possível selecionar um período e os tipos de registros que serão exibidos dentro do relatório.
            Na frente de cada tipo de registro será exibido a quantidade de erros retornados pela RNDS durante o período
            selecionado.
          </Text>
          <NewsImg
            src='/static/novidades/5-3_modal_relatorio_erros_rnds.png'
            alt='Imagem exibindo modal para gerar relatório de erros da RNDS'
          />
          <Text fontSize={0.875} component='li'>
            Após clicar no botão "Gerar relatório CSV" o relatório começará a ser processado e dependendo da quantidade
            de erros esse processamento pode levar alguns minutos. Segue um relatório de exemplo.
          </Text>
          <NewsImg
            src='/static/novidades/5-3_relatorio_erros_rnds.png'
            alt='Imagem exibindo csv de relatório de erros da RNDS'
          />
        </VFlow>
      </NewsHeadingSection>
    </VFlow>
  )
}

const styles = {
  iconBold: css`
    margin-right: 0.25rem;
    width: 32px;
    height: 32px;
  `,
  iconSVG: css`
    margin-right: 0.25rem;
    width: 32px;
    height: 32px;
  `,
}

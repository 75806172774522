/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { CheckboxProps, Heading, Select, SelectMenuItem, SelectProps, Text, Theme, useTheme } from 'bold-ui'
import { Checkbox, HFlow, SelectEmptyItem, SelectHelperMenuItem, Tag, useLocale, VFlow } from 'bold-ui'
import {
  defaultComponents as selectMenuDefaultComponents,
  SelectDownshiftMenuProps,
} from 'bold-ui/lib/components/Select/SelectSingle/SelectDownshiftMenu'
import {
  DEFAULT_SELECT_PAGE_PARAM,
  DEFAULT_SELECT_SIZE,
  useAsyncQuerySelect,
} from 'components/form/field/select/useAsyncQuerySelect'
import { HLabel } from 'components/HLabel'
import { Cpf } from 'components/label'
import { LotacoesAgendaSelectDocument } from 'graphql/hooks.generated'
import {
  AcessoCbo,
  LotacaoAgendaSelectFragment,
  LotacoesAgendaSelectQuery,
  LotacoesAgendaSelectQueryVariables,
} from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { ReactComponent as PinFilled } from 'images/agenda/pin-filled.svg'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { emptyArray } from 'util/array'
export type LotacaoAgendaSelectModel = LotacaoAgendaSelectFragment

type LotacaoAgendaSelectModelInternal =
  | 'CABECALHO_FIXADOS'
  | 'CABECALHO_OUTROS'
  | 'FIXADOS_VAZIO'
  | 'OUTROS_VAZIO'
  | LotacaoAgendaSelectModel

export interface LotacaoAgendaSelectProps
  extends Omit<SelectProps<LotacaoAgendaSelectModelInternal>, 'items' | 'itemToString'> {
  loadItemsOnOpen?: boolean
  isAgendaAd: boolean
  somenteCadastrarAgendamento?: boolean
  cbosAcesso?: AcessoCbo[]
  excludeProfissionaisIds?: ID[]
  includeEquipesComVinculo?: boolean
  hasAgendaFixada?: boolean
}

interface ItemProps {
  item: LotacaoAgendaSelectModelInternal
  index: number
}

LotacaoAgendaSelect.defaultProps = {
  loadItemsOnOpen: true,
  excludeProfissionaisIds: emptyArray,
} as Partial<LotacaoAgendaSelectProps>

export function LotacaoAgendaSelect(props: LotacaoAgendaSelectProps) {
  const {
    loadItemsOnOpen,
    isAgendaAd = false,
    somenteCadastrarAgendamento,
    cbosAcesso,
    excludeProfissionaisIds,
    includeEquipesComVinculo = false,
    hasAgendaFixada = false,
    ...rest
  } = props

  const locale = useLocale()
  const [incluirInativasComAgendamentosFuturos, setIncluirInativasComAgendamentosFuturos] = useState(false)

  // Adicionado pois a query deve desconsiderar o filtro da combo nos casos em que o checkbox de lotações inativas for alterado
  const [filterByInput, setFilterByInput] = useState(true)

  const { analytics } = useFirebase()
  useEffect(() => {}, [analytics, incluirInativasComAgendamentosFuturos])

  const handleIncluirInativasCheckboxChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      const checked = ev.target.checked
      if (checked) analytics.logEvent('incluir_lotacoes_inativas_AG')
      setIncluirInativasComAgendamentosFuturos(checked)
      loadItemsOnOpen && setFilterByInput(false)
    },
    [analytics, loadItemsOnOpen]
  )

  const skip = useCallback((inputString) => !loadItemsOnOpen && inputString?.trim()?.length < 2, [loadItemsOnOpen])
  const variables = useCallback(
    (inputQuery: string) => {
      const apenasFixadas = (inputQuery?.length ?? 0) < 2 && hasAgendaFixada
      const query = filterByInput ? inputQuery : null
      return {
        input: {
          query,
          isAgendaAd,
          somenteCadastrarAgendamento,
          incluirInativasComAgendamentosFuturos,
          cbosAcesso,
          excludeProfissionaisIds,
          includeEquipesComVinculo,
          pageParams,
        },
        inputAgendaFixada: { query, excludeProfissionaisIds },
        apenasFixadas,
      }
    },
    [
      filterByInput,
      isAgendaAd,
      somenteCadastrarAgendamento,
      incluirInativasComAgendamentosFuturos,
      cbosAcesso,
      excludeProfissionaisIds,
      includeEquipesComVinculo,
      hasAgendaFixada,
    ]
  )

  const { skipping, selectProps } = useAsyncQuerySelect<
    LotacaoAgendaSelectModelInternal,
    LotacoesAgendaSelectQuery,
    LotacoesAgendaSelectQueryVariables
  >({
    query: LotacoesAgendaSelectDocument,
    extractItems,
    variables,
    skip,
    debounceTime: 500,
    refetchOnVariablesChange: true,
  })

  return (
    <Select<LotacaoAgendaSelectModelInternal>
      renderItem={renderItem}
      itemToString={itemToString}
      placeholder='Busque um profissional pelo seu nome, CNS, equipe ou CBO'
      onInput={() => setFilterByInput(true)}
      components={{
        Item,
        EmptyItem: () =>
          !loadItemsOnOpen ? (
            <SelectHelperMenuItem>
              {skipping ? 'Digite para buscar um profissional.' : locale.select.emptyItem}
            </SelectHelperMenuItem>
          ) : (
            <SelectEmptyItem />
          ),
        AppendItem: () =>
          !somenteCadastrarAgendamento && (
            <IncluirInativasComAgendamentosFuturosCheckbox
              checked={incluirInativasComAgendamentosFuturos}
              onChange={handleIncluirInativasCheckboxChange}
            />
          ),
      }}
      {...selectProps}
      {...rest}
    />
  )
}

interface ItemProps extends SelectDownshiftMenuProps<LotacaoAgendaSelectModelInternal> {
  item: LotacaoAgendaSelectModelInternal
  index: number
}

const Item = (itemProps: ItemProps) => {
  const { index, ...rest } = itemProps

  const {
    downshift: { getItemProps },
    item,
    renderItem,
  } = rest
  if (item === 'CABECALHO_FIXADOS' || item === 'CABECALHO_OUTROS') {
    return <GrupoHeader grupoTipo={item} itemProps={getItemProps({ item, index })} />
  }
  if (item === 'FIXADOS_VAZIO' || item === 'OUTROS_VAZIO') {
    return <GrupoEmptyMessage grupoTipo={item} />
  }

  return index < DEFAULT_SELECT_SIZE ? (
    <selectMenuDefaultComponents.Item index={index} {...rest}>
      {renderItem(item)}
    </selectMenuDefaultComponents.Item>
  ) : (
    <MaisItensSelectMenuItem {...getItemProps({ item, index })} />
  )
}

const MaisItensSelectMenuItem = () => (
  <SelectHelperMenuItem
    onClick={(ev) => {
      ev.stopPropagation()
      ev.preventDefault()
    }}
  >
    Mostrando somente os primeiros {DEFAULT_SELECT_SIZE} resultados. Para ver mais resultados refine sua busca.
  </SelectHelperMenuItem>
)

const IncluirInativasComAgendamentosFuturosCheckbox = (props: Pick<CheckboxProps, 'checked' | 'onChange'>) => {
  const theme = useTheme()
  return (
    <div
      data-testid='incluir-inativas-checkbox-container'
      css={css`
        padding: 0.5rem;
        background-color: ${theme.pallete.surface.background};
      `}
    >
      <Checkbox label='Mostrar lotações inativas' {...props} />
    </div>
  )
}

interface GrupoEmptyMessageProps {
  grupoTipo: 'FIXADOS_VAZIO' | 'OUTROS_VAZIO'
}

function GrupoEmptyMessage(props: GrupoEmptyMessageProps) {
  const { grupoTipo } = props
  const theme = useTheme()
  const styles = createStyles(theme)

  return (
    <SelectMenuItem style={styles.disabledItem}>
      <Text fontStyle='italic'>
        {grupoTipo === 'FIXADOS_VAZIO'
          ? 'Fixe um profissional para adicioná-lo à lista de acesso rápido.'
          : 'Digite para pesquisar por outros profissionais.'}
      </Text>
    </SelectMenuItem>
  )
}

interface GrupoHeaderProps {
  grupoTipo: 'CABECALHO_FIXADOS' | 'CABECALHO_OUTROS'
  itemProps?: ItemProps
  children?: React.ReactNode
}

//TODO (RNG): Avaliar generalizar este componente para ser usado em outros selects (#21942)
function GrupoHeader(props: GrupoHeaderProps) {
  const { grupoTipo, itemProps = {} } = props
  const theme = useTheme()
  const styles = createStyles(theme)

  return (
    <SelectMenuItem css={styles.headerItem} {...itemProps}>
      {grupoTipo === 'CABECALHO_FIXADOS' ? (
        <HFlow alignItems='center' hSpacing={0.2}>
          <Heading level={4} color='primary'>
            Acesso rápido
          </Heading>
          <PinFilled css={styles.pinIcon} />
        </HFlow>
      ) : (
        <Heading level={4} color='primary'>
          Outros Profissionais
        </Heading>
      )}
    </SelectMenuItem>
  )
}
const extractItems = (data: LotacoesAgendaSelectQuery): LotacaoAgendaSelectModelInternal[] => {
  const fixados = data?.lotacoesFixadasAgenda ?? []
  const outros = data?.lotacoesAgenda?.content ?? []
  const outrosSemFixados = outros.filter((item) => !fixados.some((fixado) => fixado.id === item.id))

  return [
    'CABECALHO_FIXADOS',
    ...(fixados.length > 0 ? fixados : ['FIXADOS_VAZIO' as LotacaoAgendaSelectModelInternal]),
    'CABECALHO_OUTROS',
    ...(outrosSemFixados.length > 0 ? outrosSemFixados : ['OUTROS_VAZIO' as LotacaoAgendaSelectModelInternal]),
  ]
}
const itemToString = (item: LotacaoAgendaSelectModelInternal) =>
  typeof item === 'string' ? item : item?.profissional.nome
const renderItem = (item: LotacaoAgendaSelectModelInternal) => {
  switch (item) {
    case 'FIXADOS_VAZIO':
    case 'OUTROS_VAZIO':
      return <GrupoEmptyMessage grupoTipo={item} />
    case 'CABECALHO_FIXADOS':
    case 'CABECALHO_OUTROS':
      return <GrupoHeader grupoTipo={item} />
    default:
      return (
        <VFlow vSpacing={0}>
          <Text fontWeight='bold'>
            {item.profissional.nome} {item.profissional.nomeSocial && '(Nome social)'} -{' '}
            <Cpf value={item.profissional.cpf} />
          </Text>
          <HLabel title='CBO '>
            {item.cbo.nome} - {item.cbo.cbo2002}
          </HLabel>
          <HLabel title='Equipe '>
            {' '}
            {(item.equipe && `${item.equipe.nome} - ${item.equipe.ine}`) || 'Sem equipe'}
          </HLabel>
          <HFlow>
            {!item.ativo && <Tag>Inativo</Tag>}
            {!item.hasConfiguracaoAgenda && <Tag>Sem agenda</Tag>}
          </HFlow>
        </VFlow>
      )
  }
}

const pageParams = {
  ...DEFAULT_SELECT_PAGE_PARAM,
  size: DEFAULT_SELECT_SIZE + 1,
  sort: ['nome'],
}

const createStyles = (theme: Theme) => ({
  headerItem: css`
    pointer-events: none;
    cursor: not-allowed;
    padding: 0.3rem 0.5rem;
    background-color: ${theme.pallete.primary.c90};
  `,
  disabledItem: css`
    pointer-events: none;
    cursor: not-allowed;
  `,
  pinIcon: css`
    fill: ${theme.pallete.primary.c40};
    height: 1rem;
    width: 1rem;
  `,
})
